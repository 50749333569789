// service style one





.single-service-area-4{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 28px;
    .icon{
        margin-bottom: 20px;
    }
    .title{
        font-size: 22px;
        color: #083A5E;
    }
    p.disc{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #497696;
        margin-bottom: 20px;
    }
    a{
        color: #083A5E;
        display: flex;
        align-items: center;
        transition: .3s;
        &:hover{
            color: var(--color-primary);
        }
        i{
            margin-left: 8px;
        }
    }
}

.feature-nav-btn{
    .nav-link {
        background: #FFFFFF;
        border: 1px solid #E6E5FF;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 37px 40px;
        cursor: pointer;
        display: block;
        overflow: hidden;
        position: relative;
        .single-core-feature{
            .title{
                margin-bottom: 10px;
                color: #083A5E;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
            p.disc{
                color: #497696;
                font-size: 16px;
                line-height: 22px;
            }
        }
        &.active{
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            border: 1px solid transparent;
            &::after{
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                height: 100%;
                width: 6px;
                background: var(--color-primary);
            }
        }
    }
}

.mySwiper-t4{
    position: relative;
    padding-bottom: 80px;
    .swiper-pagination-bullet{
        background: #BCBCFF;
        opacity: 1;
    }
    .swiper-pagination-bullet-active{
        background: #BCBCFF;
        opacity: 1;
        width: 18px;
        height: 8px;
        background: #6D6CFF;
        border-radius: 5px;
    }
}