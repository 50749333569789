/*=== Scss File indexing Here===========

1. variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation'
10.sal
11.banner
12.button
13.header
14.nav
15.brand
16.common
17.feature
18.tools
19.faq
20.pricing
21.cta
22.footer
23.backto top
24.blog
25.team
26.case
27.steps
28.breadcrumb
29.about
30.use-case
31.contact
32.marquree
33.service
34.video
35.video
36.side-bar

========================================*/



/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';

/* elements */

@import'elements/banner';
@import'elements/button';
@import'elements/header';
@import'elements/nav';
@import'elements/brand';
@import'elements/common';
@import'elements/feature';
@import'elements/tools';
@import'elements/faq';
@import'elements/pricing';
@import'elements/cta';
@import'elements/footer';
@import'elements/back-to-top';
@import'elements/blog';
@import'elements/team';
@import'elements/case';
@import'elements/steps';
@import'elements/breadcrumb';
@import'elements/about';
@import'elements/use-case';
@import'elements/contact';
@import'elements/marquree';
@import'elements/service';
@import'elements/video';
@import'elements/side-bar';