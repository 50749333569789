.about-p-right{
    .inner-content{
        p.disc-l{
            width: 98%;
        }
        .rating-area{
            display: flex;
            align-items: center;
            gap: 25px;
            margin-top: 30px;
            @media #{$sm-layout} {
                flex-direction: column;
                align-items: flex-start;
            }
            .rating{
                display: flex;
                align-items: center;
                padding: 10px;
                background: #083A5E;
                max-width: max-content;
                img{
                    margin: 0 2px;
                }
            }
            p.disc{
                color: #083A5E;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
        }
        .brand-arae{
            display: flex;
            align-items: center;
            gap: 50px;
            margin-top: 30px;
            @media #{$smlg-device} {
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}
.large-image-about-p{
    text-align: center;
}

.inner-page{
    .title-conter-area{
        span.pre-title-bg{
            margin-bottom: 10px !important;
        }
    }
}

.gradient-about-title{
    background: -webkit-linear-gradient(-0deg, #3F3EED, #33B89F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.rating-area-main-p{
    text-align: center;
    .rating-area{
        display: flex;
        align-items: center;
        gap: 25px;
        margin-top: 30px;
        max-width: max-content;
        margin: auto;
        margin-bottom: 30px;
        .rating{
            display: flex;
            align-items: center;
            padding: 10px;
            background: #083A5E;
            max-width: max-content;
            img{
                margin: 0 2px;
            }
        }
        p.disc{
            color: #083A5E;
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            width: max-content;
        }
    }
    p.disc-5{
        text-transform: lowercase;
        color: #083A5E;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 25px;
    }
    .brand-arae{
        display: flex;
        align-items: center;
        gap: 50px;
        margin-top: 30px;
        justify-content: center;
    }
}

.about-inner-main-four{
    p.disc{
        margin-bottom: 30px;
    }
    .check-main-wrapper{
        display: block;
        margin-bottom: 50px;
        .single-check{
            display: flex;
            align-items: center;
            gap: 25px;
            margin: 10px 0;
            p{
                color: #083A5E;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    a.rts-btn{
        padding: 13px 40px;
    }
}

.about-image-thu-5{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.small-image-area{
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    .small-image{
        border-radius: 22px;
        display: flex;
        align-items: center;
        padding: 9px 18px;
        background: #F6F6F6;
        gap: 15px;
        border: 1px solid transparent;
        transition: .3s;

        p{
            color: #083A5E;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
        }
        &:hover{
            border: 1px solid var(--color-primary);
        }
    }
    .image-author-language{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: 20px;
        span{
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
        }
    }
}

.easy-freature-area-wrapper{
    .imge-thumb-vedio{
        position: relative;
        .video-play-button{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            span{
                display: block;
                position: relative;
                z-index: 3;
                width: 0;
                height: 0;
                border-left: 18px solid var(--color-primary);
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 47%;
            }
            &::after{
                background: #BCBCFF;
                border: none;
                width: 100px;
                height: 100px;
            }
            &::before{
                background: rgba(228, 227, 255, 0.6);
            }
        }
    }
}

.single-tab-main-content-area-five{
    .rts-btn {
        padding: 11px 25px;
    }
}