// nav style
.nav-area-main{
    nav{
        ul{
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            li{
                margin: 0 20px;
                a{
                    padding: 30px 0;
                    display: block;
                    letter-spacing: 0.03em;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }
    }
}

.header-style-three{
    nav{
        ul{
            li{
                &.has-dropdown{
                    &:hover{
                        a.links-main{
                            color: var(--color-primary-2) !important;
                        }
                    }
                }
            }
        }
    }
}

.nav-area-main{
    ul{
        li.has-dropdown{
            position: relative;
            margin-right: 38px;
            &::after{
                position: absolute;
                content: '\f078';
                font-family: 'fontAwesome';
                font-size: 14px;
                right: -20px;
                top: 51%;
                transform: translateY(-50%);
                color: #fff;
            }
            .submenu{
                position: absolute;
                min-width: 100%;
                height: auto;
                position: absolute;
                top: 100%;
                transform: scaleY(0);
                left: 0;
                z-index: 90;
                opacity: 0;
                visibility: hidden;
                text-align: left;
                transition: all 0.3s;
                border-radius: 0 0 5px 5px;
                background-color: #ffffff;
                // border-left: 1px solid #ffffff14;
                // border-bottom: 1px solid #ffffff14;
                // border-right: 1px solid #ffffff14;
                display: inline-block;
                box-shadow: 0 36px 35px rgba(61, 60, 60, 0.08);
                transform-origin: 0 0 0;
                padding: 10px 30px 10px 10px;
                min-width: 180px;
                li{
                    min-width: max-content;
                    margin: 0;
                    a{
                        color: #083A5E;
                        padding: 5px 10px 5px 15px;
                        transition: .3s;
                        // border: 1px solid transparent;
                        &:hover{
                            background: transparent;
                            color: var(--color-primary);
                            // border: 1px solid #083A5E;
                        }
                    }
                }
            }
            &:hover{
                &::after{
                    content: '\f077';
                    color: var(--color-primary);
                }
                .submenu{
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                    transform: scaleY(1);
                }
            }
            &:hover{
                a.links-main{
                    color: var(--color-primary) !important;
                }
            }
        }
    }
}


.has-dropdown.mega-menu{
    position: static !important;
}

.has-dropdown.mega-menu{
    &:hover{
        .rts-mega-menu{
            opacity: 1;
            visibility: visible;
            top: 100%;
            transform: scaleY(1);
        }
    }
}

.header-one .nav-area-main nav ul li a.links-main{
    transition: .3s;
    &:hover{
        color: var(--color-primary);
    }
}
.header-two .nav-area-main nav ul li a.links-main{
    transition: .3s;
    &:hover{
        color: var(--color-primary);
    }
}

.rts-mega-menu{
    position: absolute;
    width: 100%;
    height: auto;
    top: 100%;
    transform: scaleY(0);
    left: 0;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    transition: all 0.3s;
    border-radius: 0 0 5px 5px !important;
    background-color: #ffffff;
    display: inline-block;
    box-shadow: 0 36px 35px rgba(61, 60, 60, 0.08);
    transform-origin: 0 0 0;
    padding: 30px;
    // box-shadow: 0px 25px 60px 0px rgba(0, 0, 0, 0.1);
}

.mega-menu-item{
    padding: 0;
    margin: 0;
    flex-direction: column;
    display: flex;
    align-items: flex-start !important;
    li{
        margin-bottom: 19px;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        &:hover{
            a{
                border: 1px solid #E6E5FF;
                border-radius: 4px;
            }
        }
        a{
            display: flex !important;
            align-items: center;
            padding: 10px !important;
            border: 1px solid transparent;
            width: 90%;
            img{
                margin-right: 16px;
                padding: 10px;
                max-width: max-content;
                background: #F0F0FF;
                border-radius: 4px;
            }
            .info{
                p{
                    margin-bottom: 2px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    color: #083A5E;
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #497696;
                }
            }
        }
    }
}